import React from 'react';
import Card from '../../components/Card';
import { useIntl } from 'react-intl';
import messages from './messages';
import SubscriptionIcon from '../../assets/icons/subscription-icon.svg';
import CheckIcon from '../../assets/icons/subscription-check-icon.svg';
import { isMobile } from 'react-device-detect';

interface StatusPaymentComponentProps {
  title: string;
  icon?: any;
  subTitle: string;
  buttonText: string;
  onClick: () => void;
}

const StatusPaymentComponent = (props: StatusPaymentComponentProps) => {
  const { title, subTitle, icon, buttonText, onClick } = props;
  const { formatMessage } = useIntl();

  return (
    <Card style={CardStyles}>
      <div className="flex flex-col" style={{ gap: '26px' }}>
        <div className="flex flex-col" style={{ gap: '34px' }}>
          <div className="flex flex-col" style={{ gap: '6px' }}>
            <div className="flex" style={{ gap: '6px' }}>
              <img
                src={icon}
                alt=""
                style={{ width: '32px', height: '32px' }}
              />
              <span style={titleStyles}>{title}</span>
            </div>
            <div>
              <span style={subTitleStyles}>{subTitle}</span>
            </div>
          </div>
          <div className="flex flex-col subscription-bank-details-container">
            <div className="flex justify-between">
              <div className="flex claim-information-icon-container">
                <img src={SubscriptionIcon} width={24} height={24} />
                <span className="claim-information-icon-label">
                  {formatMessage(messages.premiumLabel)}
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <span>{formatMessage(messages.subscriptionPriceLabel)}</span>
                </div>
              </div>
            </div>
            <div>
              <div className="flex">
                <span>
                  {formatMessage(messages.premiumCreditsLabel)}:{' '}
                  <strong>{formatMessage(messages.premiumCreditsInfo)}</strong>
                </span>
              </div>
            </div>
            <div className="flex flex-col" style={{ gap: '18px' }}>
              <div className="flex" style={{ width: '182.24px', gap: '6px' }}>
                <img src={CheckIcon} />
                <span>
                  {formatMessage(messages.subscriptionCompenstationLabel)}
                </span>
              </div>
              <div className="flex" style={{ width: '147.24px', gap: '6px' }}>
                <img src={CheckIcon} />
                <span>
                  {formatMessage(messages.subscriptionCommissionLabel)}
                </span>
              </div>
              <div className="flex" style={{ width: '198.24px', gap: '6px' }}>
                <img src={CheckIcon} />
                <span>{formatMessage(messages.subscriptionCardInfoLabel)}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button style={buttonStyles} onClick={onClick}>
            {buttonText}
          </button>
        </div>
      </div>
    </Card>
  );
};

export default StatusPaymentComponent;

const CardStyles = {
  padding: isMobile ? '16px' : '32px',
  borderRadius: '12px',
  marginTop: isMobile ? '0.5rem' : 'none',
};

const titleStyles = {
  fontWeight: '600px',
  fontSize: '30px',
  lineHeight: '36.31px',
  letterSpacing: '-0.02em',
  color: '#101828',
};

const subTitleStyles = {
  fontWeight: '400px',
  fontSize: '16px',
  lineHeight: '19.36px',
  color: '#667085',
};

const buttonStyles = {
  color: 'white',
  backgroundColor: '#F36B12',
  padding: '12px 20px',
  borderRadius: '6px',
  fontWeight: 600,
};
